.NotificationsComponent {
  position: absolute;
  z-index: 3;
  right: 0;
  max-width: 35%;
  margin: 0 5%;
}

.NotificationsComponent .notification {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin: 5px 0;
  color: white;
}

.NotificationsComponent .error-notification {
  background-color: red;
}

.NotificationsComponent .success-notification {
  background-color: green;
}

.NotificationsComponent .notification-closer {
  margin-left: 5px;
  min-width: 1.4rem;
  min-height: 1.4rem;
  cursor: pointer;
  transition-property: color;
  transition-duration: 0.5s;
  color: rgb(200, 200, 200);
}

.NotificationsComponent .notification-closer:hover {
  color: white;
}

@media (max-width: 800px) {
  .NotificationsComponent {
    margin: 0 10px;
  }
}
