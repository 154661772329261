.app {
  width: 90%;
  margin: auto;
  min-width: 300px;
}

@media (max-width: 800px) {
  .app {
    width: 100%;
    padding: 0 10px;
  }
}